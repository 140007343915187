<template>
  <div class="user_info_wrap">

    <div class="left_menu">
      <div class="group">
        <div class="title">
          账号
        </div>

        <div class="line"
             @click="toPage('/info')">
          <img src="@/assets/personalCenter/获取手机.png"
               alt="">个人信息<img src="@/assets/personalCenter/前往.png"
               class="right_icon"
               alt="">
        </div>

        <div class="line"
             v-if="userInfo.user_type_id == 14"
             @click="toPage('/vipInfo')">
          <img src="@/assets/personalCenter/会员信息.svg"
               alt="">会员信息<img src="@/assets/personalCenter/前往.png"
               class="right_icon"
               alt="">
          <img v-if="isFree"
               class="free-vip-wrap"
               src="@/assets/personalCenter/icon_vip_free_btn.png" />
        </div>
        <div class="line"
             @click="toPage('/studyGoal')">
          <img src="@/assets/personalCenter/studyGoal.jpg"
               alt="">{{userInfo.edu_grade_id == 3?'学习目标': '高考目标'}}<img src="@/assets/personalCenter/前往.png"
               class="right_icon"
               alt="">
        </div>
        <div class="line"
             @click="toPage('/studyInfo')">
          <img src="@/assets/personalCenter/icon-studyInfo.png"
               alt="">学习信息<img src="@/assets/personalCenter/前往.png"
               class="right_icon"
               alt="">
        </div>
        <div class="line"
             @click="toPage('/resetPwd')">
          <img src="@/assets/personalCenter/修改密码.png"
               alt="">修改密码<img src="@/assets/personalCenter/前往.png"
               class="right_icon"
               alt="">
        </div>
        <!-- <div class="line"
             v-if="is_retail == 0"
             @click="toPage('/subjectClass')">
          <img src="@/assets/personalCenter/科目班级.png"
               alt="">科目班级<img src="@/assets/personalCenter/前往.png"
               class="right_icon"
               alt="">
        </div> -->
      </div>
      <div class="group">
        <div class="title">
          关于我们
        </div>
        <div class="line"
             @click="toPage('/concat')">
          <img src="@/assets/personalCenter/联系我们.png"
               alt="">联系我们<img src="@/assets/personalCenter/前往.png"
               class="right_icon"
               alt="">
        </div>
        <div class="line"
             @click="toPage('/protocol')">
          <img src="@/assets/personalCenter/用户协议.png"
               alt="">用户协议<img src="@/assets/personalCenter/前往.png"
               class="right_icon"
               alt="">
        </div>
        <div class="line"
             @click="toPage('/help')">
          <img src="@/assets/personalCenter/帮助.png"
               alt=""> 帮助<img src="@/assets/personalCenter/前往.png"
               class="right_icon"
               alt="">
        </div>
      </div>
      <div class="group">
        <div class="title">
          设置
        </div>
        <div class="line"
             @click="toPage('/feedback')">
          <img src="@/assets/personalCenter/我的反馈.png"
               alt="">我的反馈<img src="@/assets/personalCenter/前往.png"
               class="right_icon"
               alt="">
        </div>
        <div class="line"
             @click="toPage('/delPaper')">
          <img src="@/assets/personalCenter/我的反馈.png"
               alt="">我删除的试卷<img src="@/assets/personalCenter/前往.png"
               class="right_icon"
               alt="">
        </div>
        <!-- <div class="line">
          <img src="@/assets/personalCenter/设置试卷难度.png"
               alt="">设置试卷难度<img src="@/assets/personalCenter/前往.png"
               class="right_icon"
               alt="">
        </div> -->
        <div class="line"
             @click="logOut">
          <img src="@/assets/personalCenter/退出登录.png"
               alt="">退出登录<img src="@/assets/personalCenter/前往.png"
               class="right_icon"
               alt="">
        </div>
      </div>
    </div>
    <div class="right_view">
      <div style="margin-top:100rem">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { getVipInfo } from '@/api/vip.js'

export default {
  data () {
    return {
      is_retail: 0,
      isFree: true,
      userInfo:{}
    }
  },

  mounted () {
    this.is_retail = JSON.parse(window.localStorage.getItem('userInfo')).is_retail
    this.userInfo=JSON.parse(window.localStorage.getItem('userInfo'))
    this.loadVipInfo()
  },
  methods: {
    loadVipInfo () {
      let that = this
      getVipInfo().then(res => {
        let vipInfo = res.data
        if (vipInfo && vipInfo.vip_end_time != null && vipInfo.vip_end_time.length > 0) {
          let curDate = new Date()
          let vipExpired = new Date(vipInfo.vip_end_time)
          that.isFree = curDate > vipExpired
          that.$EventBus.emit('vipIsExpiredEvent', that.isFree)
        }
      })
    },
    toPage (path) {
      if (this.$route.path == path) {
        this.$router.go(0)
        return
      }
      this.$router.push(path)
    },
    logOut () {
      this.$confirm("确定退出登录？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(res => {
        this.$http({
          url: '/api/v1/user/logout',
          method: 'get'
        }).then(res => {
          let localObj = JSON.parse(JSON.stringify(window.localStorage))
          for (let i in localObj) {
            if (i == 'opertionForm' || i == 'loginForm') {
              if (JSON.parse(localObj.opertionForm).rememberPassword == false) {
                window.localStorage.removeItem(i)
              }
            } else {
              window.localStorage.removeItem(i)
            }
          }
          this.$store.commit('CLEAR_SUBJECT')
          // window.localStorage.clear()
          this.$message.success('退出登录成功')
          this.$router.push('/login?not_auto_login=1')

          // this.$router.push('/changeLevel?level=1&not_auto_login=1')
        })
      }).catch(e => e)
    }
  }
}
</script>

<style lang="scss" scoped>
.user_info_wrap {
  display: flex;
  height: 100vh;
  .left_menu {
    overflow: auto;
    flex: 1;
    background: white;
    box-shadow: inset 0rem -1rem 0rem 0rem rgba(229, 229, 229, 1);
    .group {
      text-indent: 30rem;
      .title {
        height: 75rem;
        font-size: 24rem;
        font-weight: 500;
        color: #666666;
        line-height: 75rem;
      }
      .line {
        width: calc(100% - 30rem);
        padding-left: 30rem;
        height: 77rem;
        font-size: 24rem;
        font-weight: bold;
        color: #333333;
        display: flex;
        align-items: center;
        position: relative;
        img {
          width: 28rem;
          height: 28rem;
        }
        .right_icon {
          width: 20rem;
          height: 20rem;
          position: absolute;
          right: 30rem;
          top: 25rem;
        }
        .free-vip-wrap {
          // display: flex;
          // justify-content: center;
          // border-radius: 8px;
          // background: linear-gradient(100deg, #FDECC9 0%, #E9C383 100%);
          // color: #000;
          // font-size: 20px;
          // font-style: normal;
          // font-weight: 400;
          // line-height: 30px;
          // padding:5px 12px;

          width: 104px;
          height: 40px;
          position: absolute;
          right: 66rem;
        }
      }
    }
  }
  .right_view {
    flex: 2.5;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>